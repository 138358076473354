import axios from '@/utils/axios'

// 登录
export const login = data =>
  axios.post('admin/base/login', data)
    .then(res => res.data)

// 获取手机验证码
export const getCode = data => 
  axios.post('/mini/Yunma/getPhonecode', data)
    .then(res => res.data)

// 忘记密码
export const forGetPWD = data => 
  axios.post('/admin/users/forgetpassword', data)
    .then(res => res.data)


/* 省市区 */
export const regionList = params => axios({
  url: '/admin/area/readAdminAreaList',
  method: 'GET',
  params
}).then(res => res.data)

// 获取用户权限
export const getAdminLevel = data => 
  axios.post('/crm/ShopRetention/getAdminLevel', data)
    .then(res => res.data)