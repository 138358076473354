import axios from '@/utils/axios'

// -----------------------客户-------------------------
// 客户s手机号验证
export const verifyPhone = params => axios({
  method: 'post',
  url: '/crm/customer/verifyPhone',
  params
}).then(res => res.data)
// 客户列表
export const clientList = params => axios({
    method: 'get',
    url: '/crm/customer/list',
    params
  }).then(res => res.data)

// 全部显示客户余额
export const showBalance = params => axios({
  method: 'get',
  url: '/crm/customer/showBalance',
  params
}).then(res => res.data)

// 流失预警列表
export const clientListLS = params => axios({
  method: 'get',
  url: '/crm/customerLoss/list',
  params
}).then(res => res.data)
// 客访导出
export const getTwoLabel = params => axios({
  method: 'post',
  url: '/crm/customer/getTwoLabel',
  params
}).then(res => res)

// 客访导出
export const vistExport = params => axios({
  method: 'post',
  url: '/crm/customer/returnVistList_export',
  params
}).then(res => res)

  // 客户导出
export const clientExport = params => axios({
  method: 'post',
  url: '/crm/customer/list_export',
  params
}).then(res => res)

// 新单列表
export const newOrderlist = params => axios({
    method: 'get',
    url: '/crm/customer/newOrderlist',
    params
  }).then(res => res.data)

// 回访列表
export const clientVisitlist = params => axios({
    method: 'get',
    url: '/crm/customer/clientVisitlist',
    params
  }).then(res => res.data)

// 添加客户
export const addClient = data => axios({
  method: 'post',
  url: '/crm/customer/create',
  data
})

// 编辑客户
export const updateClient = data => axios({
  method: 'post',
  url: '/crm/customer/update',
  data
})

// 客户详情
export const clientDetail = params => axios({
  method: 'post',
  url: '/crm/customer/read',
  params
}).then(res => res.data)

// 客户详情
export const readCustomerId = params => axios({
  method: 'post',
  url: '/crm/customer/readCustomerId',
  params
}).then(res => res.data)

// 客户详情
export const transferShop = params => axios({
  method: 'post',
  url: '/crm/customer/transferShop',
  params
}).then(res => res)

// 数据字典
export const ssettingList = params => axios({
    method: 'get',
    url: '/crm/customer/setting',
    params
  }).then(res => res.data)

// 客户基本设置状态
export const statusSetting = data => axios({
  method: 'post',
  url: '/crm/customer/statusSetting',
  data
}).then(res => res.data)

// 店铺列表
export const getCustomerShopList = data => axios({
  method: 'post',
  url: '/crm/customer/getCustomerShopList',
  data
})
// 更新进店 （时间/状态）
export const setCustomerStatus = data => axios({
  method: 'post',
  url: '/crm/customer/setCustomerStatus',
  data
})

// 上传图片
export const customerImgAdd = data => axios({
  method: 'post',
  url: '/crm/customer/addAlbum',
  data
})

// 相册列表
export const customerImgList = data => axios({
  method: 'post',
  url: '/crm/customer/albumList',
  data
})

// 删除相册照片
export const customerDel = data => axios({
  method: 'post',
  url: '/crm/customer/deleteAlbum',
  data
})

// 删除案例治疗记录照片
export const caseDelImg = data => axios({
  method: 'post',
  url: '/crm/customer/deleteCaseLogImg',
  data
})

// -----------------------客访-------------------------

// 客访列表
export const returnVistList = data => axios({
  method: 'post',
  url: '/crm/customer/returnVistList',
  data
})

// 客访列表
export const addVistList = data => axios({
  method: 'post',
  url: '/crm/customer/addVistList',
  data
})
// -----------------------预存货-------------------------
// 预存货列表
export const prestockList = data => axios({
  method: 'post',
  url: '/crm/customer/prestockList',
  data
})

// 出货
export const shipment= data => axios({
  method: 'post',
  url: '/crm/customerPrestock/shipment',
  data
})
//商品表出货
export const salesReturn= data => axios({
  method: 'post',
  url: '/crm/customerPrestock/salesReturn',
  data
})

// -----------------------修复方案-------------------------
// 修复方案列表
export const repairPlanList = data => axios({
  method: 'post',
  url: '/crm/customer/repairPlanList',
  data
})

export const addPlanList = data => axios({
  method: 'post',
  url: '/crm/customer/addPlanList',
  data
})

// 新增治疗方案
export const addHealPlan = data => axios({
  method: 'post',
  url: '/crm/customer/addHealPlan',
  data
})

// 获取治疗方案
export const getHealPlan = data => axios({
  method: 'post',
  url: '/crm/customer/getHealPlan',
  data
})
// -----------------------定时回访-------------------------

// 列表
export const timingList = data => axios({
  method: 'post',
  url: '/crm/Kfdsvist/index',
  data
})

// 添加
export const addTiming= data => axios({
  method: 'post',
  url: '/crm/Kfdsvist/addDs',
  data
})

// 消费
export const disposeTiming= data => axios({
  method: 'post',
  url: '/crm/Kfdsvist/addDsXh',
  data
})
// -----------------------服务-------------------------

// 服务列表
export const serviceList = params => axios({
  method: 'get',
  url: '/crm/customer/serviceList',
  params
}).then(res => res.data)

// 核销
export const addServiceLog= data => axios({
  method: 'post',
  url: '/crm/customer/addServiceLog',
  data
})

// 调整服务
export const changeService= data => axios({
  method: 'post',
  url: '/crm/customer/changeService',
  data
})

// 服务失效
export const gbservice= data => axios({
  method: 'post',
  url: '/crm/customer/gbservice',
  data
})

// 服务详情
export const readService = params => axios({
  method: 'get',
  url: '/crm/customer/readService',
  params
}).then(res => res.data)

// 核销列表
export const serveContent = params => axios({
  method: 'get',
  url: '/crm/customer/serveContent',
  params
}).then(res => res.data)

// 护理统计
export const serviceNursing = params => axios({
  method: 'get',
  url: '/crm/customer/serviceNursing',
  params
}).then(res => res.data)

// 护理统计-门诊
export const serviceOutpatientNursing = params => axios({
  method: 'get',
  url: '/crm/customer/serviceOutpatientNursing',
  params
}).then(res => res.data)

// 服务失效
export const delservicelog= data => axios({
  method: 'post',
  url: '/crm/customer/delservicelog',
  data
})


// -----------------------优惠券-------------------------

// 优惠券列表
export const getCouponProvide = data => axios({
  method: 'post',
  url: '/crm/customer/getCouponProvide',
  data
})

// ---------------------转店申请-----------------------

// 转店列表
export const transferShopList = params => axios({
  method: 'get',
  url: '/crm/customer/transferShopList',
  params
}).then(res => res.data)

// 转店列表导出
export const transferShopExport = data => axios({
  method: 'post',
  url: '/crm/customer/transferShopList_export',
  data
}).then(res => res)


// ---------------------会员等级-----------------------

// 会员等级
export const getGradeList = params => axios({
  method: 'get',
  url: '/crm/customer/getGradeList',
  params
}).then(res => res.data)

// 最小默认值
export const addGradeDetail = data => axios({
  method: 'post',
  url: '/crm/customer/addGradeDetail',
  data
}).then(res => res)

// 会员等级详情
export const gradeDetail = data => axios({
  method: 'post',
  url: '/crm/customer/gradeDetail',
  data
}).then(res => res.data)

// 添加会员等级
export const addGrade = data => axios({
  method: 'post',
  url: '/crm/customer/addGrade',
  data
}).then(res => res)

// 编辑会员等级
export const saveGrade = data => axios({
  method: 'post',
  url: '/crm/customer/saveGrade',
  data
}).then(res => res)



// ---------------------基础设置-----------------------

// 添加基础设置
export const createSetting = data => axios({
  method: 'post',
  url: '/crm/customer/createSetting',
  data
})

// 删除基本设置
export const deleteSetting = data => axios({
  method: 'post',
  url: '/crm/customer/deleteSetting',
  data
})

// 编辑基础设置
export const updateSetting = data => axios({
  method: 'put',
  url: '/crm/customer/updateSetting',
  data
})

// 基础设置列表
export const settingList = params => axios({
  method: 'get',
  url: '/crm/customer/setting',
  params
})

// 基本设置类型
export const settingCategory = params => axios({
  method: 'get',
  url: '/crm/customer/settingCategory',
  params
})

// 基本设置详情
export const settingInfo = data => axios({
  method: 'post',
  url: '/crm/customer/settingInfo',
  data
}).then(res => res)

// ---------------------流失-----------------------


// 流失列表
export const drainlist = params => axios({
  method: 'get',
  url: '/crm/customer/drainlist',
  params
}).then(res => res.data)

// ---------------------预警-----------------------


// 预警列表
export const warninglist = params => axios({
  method: 'get',
  url: '/crm/customer/warninglist',
  params
}).then(res => res.data)


// ---------------------审核列表-----------------------

// 审核列表
export const auditList = params => axios({
  method: 'get',
  url: '/crm/customer/auditList',
  params
}).then(res => res.data)

// 提交审核
export const submitAudit = params => axios({
  method: 'get',
  url: '/crm/customer/submitAudit',
  params
}).then(res => res.data)

// 预约列表
export const booksList = params => axios({
  method: 'get',
  url: '/crm/customer/staffBooksList',
  params
}).then(res => res.data)
// 新预约列表
export const newbooksList = params => axios({
  method: 'get',
  url: '/crm/customer/staffBooksList2',
  params
}).then(res => res)
// 员工同门店客户列表
export const personcustomerList = params => axios({
  method: 'get',
  url: '/crm/customer/personcustomerList',
  params
}).then(res => res)
export const submitBooks = data => axios({
  method: 'post',
  url: '/crm/customer/submitBooks',
  data
})
export const getBookId = params => axios({
  method: 'get',
  url: '/crm/customer/getBookId',
  params
}).then(res => res)
export const cancelBook = data => axios({
  method: 'post',
  url: '/crm/customer/cancelBook',
  data
})

// ---------------------积分-----------------------
export const getEarnPointList = params => axios({
  method: 'get',
  url: '/mini/customerPoint/getEarnPointList',
  params
}).then(res => res)
export const getPersonPoint = params => axios({
  method: 'get',
  url: '/mini/customerPoint/getPersonPoint',
  params
}).then(res => res)
export const pointOriginList = params => axios({
  method: 'get',
  url: '/mini/customerPoint/pointOriginList',
  params
}).then(res => res)
export const addPointOrigin = data => axios({
  method: 'post',
  url: '/mini/customerPoint/addPointOrigin',
  data
})
export const updatePointOrigin = data => axios({
  method: 'post',
  url: '/mini/customerPoint/updatePointOrigin',
  data
})
export const changeOriginStatus = data => axios({
  method: 'post',
  url: '/mini/customerPoint/changeOriginStatus',
  data
})
export const getPointGoodsEnableLog = params => axios({
  method: 'get',
  url: '/mini/customerPoint/getPointGoodsEnableLog',
  params
}).then(res => res)


// ---------------------申报优案------------------
export const customerAddCase = data => axios({
  method: 'post',
  url: '/crm/addCase/customerAddCase',
  data
})

// ---------------------预存货------------------
export const prestockGoodslistDetail = data => axios({
  method: 'post',
  url: '/crm/CustomerPrestock/prestockGoodslistDetail',
  data
})