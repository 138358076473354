<template>
  <div class='page-header'>
    <span class="title">{{ title }}</span>

    <div class="setting">
      <!-- <img class="backImg" src="@/assets/images/refresh.png" alt /> -->
      <img class="backImg" v-if="!menuList.includes(title)" @click="back" src="@/assets/images/back.png" alt />

      <!-- <slot>
        <el-button size="small" class="success" type="success">刷新</el-button>
      </slot> -->
    </div>

  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      menuList: ['保有数据表明细','市场保有数据表明细','月度报表详情']
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>
.page-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ddd;
  .title {
    color: #999999;
    padding-left: 10px;
    border-left: 4px solid $theme-color;
  }
  .setting {
    margin-left: auto;
    height: 32px;
    line-height: 32px;
  }
  .success {
    @include successBtn(100px, 30px);
  }

  .backImg {
    margin-right: 14px ;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }

}
</style>
